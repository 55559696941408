<template>
  <div class="section">
    <div
      class="transaction__filter--container py-2 pl-0 pr-2 justify-content-between"
      style="height:82px;background-color: #f6f7fa;"
    >
      <b-button-group>
        <b-button
          v-for="(item, index) in listFilter"
          v-if="checkPermission(item.permission)"
          :key="`${item.name}-${index}`"
          class="filter--button"
          :class="{ 'active' : item.name == filter }"
          @click="filter = item.name, comp = (item.name == 'Utang' ? 'TableDebt' : (item.name == 'Voucher' ? 'TableVoucher' : 'TablePiutang') )"
        >
          {{ item.name }}
        </b-button>
      </b-button-group>
      <div class="d-flex search__export align-items-center">
        <b-button
          v-if="checkPermission('add voucher') && comp == 'TableVoucher'"
          variant=""
          @click="$store.commit('vouchers/setUuid', false), $bvModal.show('modal-voucher')"
        >
          <span>Tambah Voucher</span>
        </b-button>
      </div>
    </div>

    <keep-alive>
      <TableDebt
        v-if="checkPermission('list utang') && comp == 'TableDebt'"
        :result="rows"
        :loading="isLoading"
      />
      <TablePiutang
        v-if="checkPermission('list piutang') && comp == 'TablePiutang'"
        :result="rows"
        :loading="isLoading"
      />
      <TableVoucher
        v-if="checkPermission('list voucher') && comp == 'TableVoucher'"
        :loading="isLoading"
        :loading-voucher="isLoadingVoucher"
      />
      <!-- <component :is="comp" /> -->
    </keep-alive>
    <ModalDebt />
    <ModalVoucher
      :post-voucher="postVoucher"
      :is-loading="isLoadingVoucher"
      :messages="messages"
    />
    <ModalPiutang />
    <ModalPayment
      :get-utang="getUtang"
      :get-piutang="getPiutang"
    />
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, VBToggle,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import ModalDebt from '@/components/Debt/Modal/ModalDebt.vue'
import ModalPiutang from '@/components/Debt/Modal/ModalPiutang.vue'
import ModalPayment from '@/components/Debt/Modal/ModalPayment.vue'
import ModalVoucher from '@/components/Voucher/ModalVoucher.vue'
import TableDebt from '@/components/Debt/TableDebt.vue'
import TablePiutang from '@/components/Debt/TablePiutang.vue'
import TableVoucher from '@/components/Voucher/TableVoucher.vue'

export default {
  title() {
    return 'Utang & Piutang'
  },
  components: {
    BButton,
    BButtonGroup,
    TableDebt,
    TablePiutang,
    TableVoucher,
    ModalDebt,
    ModalPayment,
    ModalPiutang,
    ModalVoucher,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      comp: 'TableDebt',
      // listFilter: ['Utang', 'Piutang', 'Voucher'],
      listFilter: [
        {
          name: 'Utang',
          permission: 'list utang',
        },
        {
          name: 'Piutang',
          permission: 'list piutang',
        },
        {
          name: 'Voucher',
          permission: 'list voucher',
        },
      ],
      filter: 'Utang',
      isLoading: false,
      isLoadingVoucher: false,
      filters: {
        per_page: 10,
        sort_type: 'desc',
      },
      rows: {},
      formPayloadVoucher: {
        supplier_uuid: '',
        voucher_code: '',
        amount: '',
        start_date: '',
        expire_date: '',
      },
      vouchers: {},
      messages: '',
    }
  },
  watch: {
    '$store.state.vouchers.formPayload': {
      handler(value) {
        this.formPayloadVoucher = value
      },
      deep: true,
    },
  },
  mounted() {
    if (this.checkPermission('list utang')) {
      this.filter = 'Utang'
      this.comp = 'TableDebt'
    } else if (this.checkPermission('list piutang')) {
      this.filter = 'Piutang'
      this.comp = 'TablePiutang'
    } else if (this.checkPermission('list voucher')) {
      this.filter = 'Voucher'
      this.comp = 'TableVoucher'
    }

    if (this.$route.query.cta == 'Piutang') {
      this.filter = 'Piutang'
      this.comp = 'TablePiutang'
    }
    if (this.$route.query.cta == 'Utang') {
      this.filter = 'Utang'
      this.comp = 'TableDebt'
    }
  },
  methods: {
    getUtang(page = 1) {
      this.isLoading = true
      const queryParams = this.filters
      queryParams.page = page
      queryParams.with_utang = 1

      this.$store.dispatch('supplier/getData', {
        params: queryParams,
      }).then(result => {
        this.isLoading = false
        this.rows = result.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    getPiutang(page = 1) {
      this.isLoading = true
      const queryParams = this.filters
      queryParams.page = page
      queryParams.with_piutang = 1

      this.$store.dispatch('customer/getData', {
        params: queryParams,
      }).then(result => {
        this.isLoading = false
        this.rows = result.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    postVoucher() {
      this.$swal({
        title: 'Konfirmasi',
        text: `Apakah Anda yakin ingin ${this.$store.state.vouchers.uuid ? 'mengubah data' : 'membuat'} Voucher ${this.$store.state.vouchers.uuid ? 'ini' : 'baru'}?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoadingVoucher = true
          const form = this.preparePayload()

          if (this.$store.state.vouchers.uuid) {
            form.append('_method', 'PATCH')
          }

          this.$store.dispatch('vouchers/postData', {
            uuid: `/${this.$store.state.vouchers.uuid || ''}` || '',
            payload: form,
          }).then(() => {
            this.isLoadingVoucher = false
            successNotification(this, 'Success', `Voucher berhasil di ${this.$store.state.vouchers.uuid ? 'ubah' : 'tambahkan'}`)
            this.$bvModal.hide('modal-voucher')
            // this.getVoucher()
          }).catch(err => {
            this.messages = err.response.data.meta.messages
            this.isLoadingVoucher = false
            console.log(err)
          })
        }
      });
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayloadVoucher) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayloadVoucher.hasOwnProperty(key) && this.formPayloadVoucher[key] != null) {
          form.append(key, this.formPayloadVoucher[key])
        }
      }
      return form
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

</style>

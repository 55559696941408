<template>
  <div>
    <b-modal
      id="modal-voucher"
      hide-header
      hide-footer
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Large Modal"
      class="custom__modal"
    >
      <div class="section">
        <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
          <b-row>
            <b-col
              cols="3"
              class="d-flex align-items-center"
            >
              <div
                style="cursor: pointer;"
                @click="$bvModal.hide('modal-voucher')"
              >
                <feather-icon
                  size="24"
                  class="text-dark font-weight-bolder"
                  icon="ArrowLeftIcon"
                />
                <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
              </div>
            </b-col>
            <b-col
              cols="6"
              class="d-flex justify-content-center align-items-center"
            >
              <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
                Tambah/Edit Voucher
              </h1>
            </b-col>
            <b-col
              cols="3"
              class="text-right"
            >
              <b-button
                class="px-3"
                @click="postVoucher()"
                :disabled="formPayload.is_used"
              >
                Simpan
              </b-button>
            </b-col>
          </b-row>
        </header>
        <b-container class="mt-5 pt-5">
          <b-row class="justify-content-center h-100 bg-white pb-lg-5 pt-lg-5">
            <b-col
              cols="12"
              md="6"
            >
              <b-row>
                <b-col cols="12" md="12" class="mb-3">
                  <h2 class="text-dark fw-bold-700">Detail Voucher</h2>
                </b-col>
                <!-- Supplier -->
                <b-col
                  cols="12"
                  md="12"
                  class="mb-2 custom__form--input"
                >
                  <label for="v-supplier">Pilih Pemasok <span class="text-danger">*</span></label>
                  <v-select
                    v-model="formPayload.supplier_uuid"
                    label="title"
                    :reduce="option => option.label"
                    :options="options"
                    placeholder="Pilih Pemasok"
                    class="text-dark fw-bold-700"
                    @search="onSearch"
                  />
                  <small
                    v-if="messages.supplier_uuid && Array.isArray(messages.supplier_uuid) && messages.supplier_uuid.length > 0"
                    class="text-danger"
                  >{{ messages.supplier_uuid.join(', ') }}</small>
                </b-col>
                <!-- Kode Voucher -->
                <b-col
                  cols="12"
                  md="6"
                  class="mb-3 custom__form--input"
                >
                  <label for="v-code-voucher">Kode Voucher <span class="text-danger">*</span></label>
                  <b-form-group>
                    <b-form-input
                      id="v-code-voucher"
                      v-model="formPayload.voucher_code"
                      type="text"
                      placeholder="Kode Voucher"
                      class="custom__input"
                    />
                  </b-form-group>
                  <small
                    v-if="messages.voucher_code && Array.isArray(messages.voucher_code) && messages.voucher_code.length > 0"
                    class="text-danger"
                  >{{ messages.voucher_code.join(', ') }}</small>
                </b-col>
                <!-- Nominal -->
                <b-col
                  cols="12"
                  md="6"
                  class="mb-3 custom__form--input"
                >
                  <label for="v-nominal">Nominal <span class="text-danger">*</span></label>
                  <b-form-group>
                    <money
                      id="v-nominal"
                      v-model="formPayload.amount"
                      v-bind="money"
                      placeholder="Nominal"
                      class="custom__input pl-1 w-100"
                    />
                  </b-form-group>
                  <small
                    v-if="messages.amount && Array.isArray(messages.amount) && messages.amount.length > 0"
                    class="text-danger"
                  >{{ messages.amount.join(', ') }}</small>
                </b-col>
                <!-- Tanggal Mulai Berlaku -->
                <b-col
                  cols="12"
                  md="6"
                  class="mb-3 custom__form--input"
                >
                  <label for="v-start">Tanggal Mulai Berlaku <span class="text-danger">*</span></label>
                  <b-form-datepicker
                    id="v-start"
                    v-model="formPayload.start_date"
                    placeholder="Tanggal Mulai Berlaku"
                    class="custom__input py-25"
                    locale="id"
                    :min="min"
                  />
                  <small
                    v-if="messages.start_date && Array.isArray(messages.start_date) && messages.start_date.length > 0"
                    class="text-danger"
                  >{{ messages.start_date.join(', ') }}</small>
                </b-col>
                <!-- Tanggal Akhir Berlaku -->
                <b-col
                  cols="12"
                  md="6"
                  class="mb-3 custom__form--input"
                >
                  <label for="v-expire">Tanggal Akhir Berlaku <span class="text-danger">*</span></label>
                  <b-form-datepicker
                    id="v-expire"
                    v-model="formPayload.expire_date"
                    placeholder="Tanggal Akhir Berlaku"
                    class="custom__input py-25"
                    locale="id"
                    :min="min"
                  />
                  <small
                    v-if="messages.expire_date && Array.isArray(messages.expire_date) && messages.expire_date.length > 0"
                    class="text-danger"
                  >{{ messages.expire_date.join(', ') }}</small>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    vSelect,
  },
  props: {
    postVoucher: {
      type: Function,
    },
    messages: {
      type: '',
    },
  },
  watch: {
    '$store.state.vouchers.formPayload': {
      handler(value) {
        this.formPayload = value
      },
      deep: true,
    },
    selectedSupplier(value) {
      this.formPayload.supplier_uuid = value
    },
    'formPayload.start_date': function (newStartDate) {
      // Update the min date for end_date datepicker
      this.min = newStartDate;
    },

    // 'formPayload.supplier_uuid': function (value) {
    //   this.getSupplierDetail(value)
    // },
    formPayload: {
      handler(value) {
        this.$store.commit('vouchers/setFormPayload', value)
      },
      deep: true,
    },
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    const minDate = new Date(today)
    return {
      comp: 'FormVoucher',
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      formPayload: {
        supplier_uuid: '',
        voucher_code: '',
        amount: 0,
        start_date: '',
        expire_date: '',
        is_used: false,
      },
      options: [],
      max: maxDate,
      min: minDate,
    }
  },
  mounted() {
    this.getSupplier()
  },
  methods: {
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.searchSupplier(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.options = []
          this.getSupplier()
          loading(false)
        }, 500)
      }
    },
    searchSupplier: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('supplier/getData', {
        uuid: '',
        params: {
          search: search,
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.options = []
          response.data.data.forEach(item => {
            vm.options.push({
              title: item.name,
              label: item.uuid,
            })
          })
          loading(false)
        })
    }, 300),
    getSupplierDetail(id) {
      this.$store.dispatch('supplier/getData', {
        uuid: id,
        params: '',
      })
        .then(result => {
          // this.uom = result.data.data.name
          this.options.push({
            label: result.data.data.uuid,
            title: result.data.data.name,
          })
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    getSupplier() {
      this.$store.dispatch('supplier/getData', {
        params: {
          per_page: 50,
        },
      }).then(result => {
        for (let index = 0; index < result.data.data.data.length; index++) {
          const element = result.data.data.data[index]
          this.options.push({
            title: element.name,
            label: element.uuid,
          })
        }
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/variables/_variables.scss';
</style>

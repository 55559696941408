<template>
  <div
    id="table-voucher"
    class="table__container"
  >
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <b-img
            class="filter-image"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
            role="button"
            @click="filter.sort_type == 'asc' ? filter.sort_type = 'desc' : filter.sort_type = 'asc'"
          />
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input
              v-model="filter.search"
              placeholder="Cari nama atau kode voucher"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1 mt-3"
    >
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table
      v-else
      max-height="80vh"
      :columns="columns"
      :rows="rows.data"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Utang Active -->
        <span v-if="props.column.field === 'amount'">
          {{ props.row.amount | formatAmount }}
        </span>
        <!-- Column: Utang Due -->
        <span v-else-if="props.column.field === 'start_date'">
          {{ moment(props.row.start_date).format('DD/MM/YYYY') }}
        </span>
        <span v-else-if="props.column.field === 'expire_date'">
          {{ moment(props.row.expire_date).format('DD/MM/YYYY') }}
        </span>
        <span v-else-if="props.column.field === 'is_used'">
          {{ props.row.is_used ? 'Sudah Dipakai' : 'Belum Dipakai' }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="checkPermission(['update voucher','delete voucher'])">
            <b-dropdown
              id="dropdown-dropleft"
              class="d-flex"
              right
            >
              <template
                #button-content
                class="btn-white text-center"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="goToDetail(props.row)" v-if="checkPermission('update voucher')">
                Detail Voucher
              </b-dropdown-item>
              <b-dropdown-item @click="deleteVoucher(props.row.uuid)" v-if="checkPermission('delete voucher')">
                Hapus Voucher
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getVoucher"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton, BCol, BRow, BSpinner, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import moment from 'moment'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BCol,
    BRow,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    getData: {
      type: Function,
    },
    results: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    loadingVoucher: {
      type: Boolean,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      moment,
      isLoading: false,
      columns: [
        {
          label: 'Pemasok',
          field: 'supplier.name',
        },
        {
          label: 'Kode Voucher',
          field: 'voucher_code',
        },
        {
          label: 'Nominal',
          field: 'amount',
        },
        {
          label: 'Start Date',
          field: 'start_date',
        },
        {
          label: 'Expire Date',
          field: 'expire_date',
        },
        {
          label: 'Status',
          field: 'is_used',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
    }
  },
  watch: {
    filter: {
      handler() {
        this.getVoucher()
      },
      deep: true,
    },
    results: {
      handler(value) {
        this.rows = value
      },
      deep: true,
    },
    loading(value) {
      this.isLoading = value
    },
    loadingVoucher(value) {
      this.isLoadingVoucher = value
      if (!value) {
        this.getVoucher()
      }
    },
  },
  created() {
    this.getVoucher()
  },
  methods: {
    goToDetail(item) {
      this.$store.commit('vouchers/setFormPayload', {
        supplier_uuid: item.supplier?.uuid,
        voucher_code: item.voucher_code,
        is_used: item.is_used,
        amount: item.amount,
        start_date: item.start_date,
        expire_date: item.expire_date,
      })
      this.$store.commit('vouchers/setUuid', item.uuid)
      this.$bvModal.show('modal-voucher')
    },
    deleteVoucher(uuid) {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus Voucher ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // User confirmed, proceed with voucher deletion
          this.isLoadingVoucher = true

          this.$store.dispatch('vouchers/deleteData', {
            uuid: `/${uuid}`,
          }).then(() => {
            this.isLoadingVoucher = false
            successNotification(this, 'Success', 'Voucher berhasil dihapus')
            this.getVoucher()
          }).catch(err => {
            this.isLoadingVoucher = false
            console.log(err)
          })
        }
      });
    },
    getVoucher(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page

      this.$store.dispatch('vouchers/getData', {
        uuid: '',
        params: queryParams,
      }).then(result => {
        this.isLoading = false
        this.rows = result.data.data
      }).catch(err => {
        if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
        this.isLoading = false
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

#table-voucher {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;
  }
}
</style>
